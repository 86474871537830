import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import gameService from "../../services/game.service";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import configData from "../../config.json";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const API_URL = configData.REACT_APP_API_URL;
const MySwal = withReactContent(Swal)
const PlayGame = ({setStatePage,setWallet,paramCredit,user,statusGame}) => {
    const navigate = useNavigate();
    const playGames = async (id,huay) => {
        MySwal.fire({
            title: 'โหลด...',
            allowOutsideClick: false,
            didOpen: () => {
                MySwal.showLoading()
            },
        });
        try {
            var id1 = id;
            if(id === 6){
                id1 = 1;
            }
            const {data: dataBeforePlay} = await gameService.beforePlay(id1);
            if(dataBeforePlay.code === 200){
                setWallet(dataBeforePlay.data.wallet);
                MySwal.close();

                if(id === 1){
                    setStatePage('betflix')
                    paramCredit.setCreditBetflix(dataBeforePlay.data.credit)
                }
                if(id === 2){
                    
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        window.open(API_URL+'/game/playGameSbobet/'+user.id);
                        MySwal.close();
                        
                    });
                }
                if(id === 3){
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        window.open(API_URL+'/game/playGameUfabet/'+user.id);
                        MySwal.close();
                    });
                    // setStatePage('ufabet')
                    // paramCredit.setCreditUfabet(dataBeforePlay.data.credit)
                }
                if(id === 5){
                    // setStatePage('huay14')
                    // paramCredit.setCreditHuay14(dataBeforePlay.data.credit)
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        
                        window.open(API_URL+'/game/playGameHuay14/'+huay+'/'+user.id);
                        
                    });
                }
                if(id === 6){
                    MySwal.fire({
                        icon: 'success',               
                        title: <p>ย้ายเครดิตสำเร็จ</p>,
                        showConfirmButton: true,
                        confirmButtonText: 'เข้าเกม',
                    }).then(async function(result){
                        const {data: dataPlayGame} = await gameService.playGameBetflix('sa', 3);
                        if(dataPlayGame.code === 200){
                            window.open(dataPlayGame.data.launch_url);
                            MySwal.close();
                        }
                        
                    });
                    // setStatePage('sa')
                    // paramCredit.setCreditSa(dataBeforePlay.data.credit)
                }
            }else{
                MySwal.fire({
                    icon: 'error',               
                    title: <p>ไม่สำเร็จ</p>,
                    text: dataBeforePlay.message,
                  })
            }
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
        
    }
    return (
        <>
        <div className="headerprocess"><i className="fal fa-gamepad-alt"></i> เข้าเกมส์</div>
        <div className="mt-3 px-3 pb-3 contain-game">
            <span className="detailaf">คำอธิบาย</span> เมื่อท่านเข้าเกมส์ ระบบจะย้ายเครดิตในกระเป๋าหลักไปยังค่ายเกมส์ที่ท่านเลือก
        </div>
        <div className="containprocess contain-game pt-2">
            <table style={{width: "100%"}}>
                <tbody>
                    <tr>
                        <td >
                            <div id="huay14_thai" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusGame.statusHuay14 ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'Thai.webp?v=0.1'} onClick={()=>{playGames(5,'lotto')}} alt="" className="img-fluid" style={{borderRadius:'20px'}}  />
                            </div>
                        </td>
                        <td >
                            <div id="huay14_lao" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusGame.statusHuay14 ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'Lao.webp?v=0.1'} onClick={()=>{playGames(5,'lotto-laos')}} alt="" className="img-fluid" style={{borderRadius:'20px'}} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <div id="huay14_hanoi" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusGame.statusHuay14 ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'Hanoi.webp?v=0.1'} onClick={()=>{playGames(5,'lotto-hanoi')}} alt="" className="img-fluid" style={{borderRadius:'20px'}} />
                            </div>
                        </td>
                        <td >
                            <div id="huay14_stock_thai" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',padding:"10px",display: statusGame.statusHuay14 ? "flex" : "none"}}>
                                <img src={IMAGE_URL+'Thai_Stock.webp?v=0.1'} onClick={()=>{playGames(5,'stock')}} alt="" className="img-fluid" style={{borderRadius:'20px'}} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <div id="huay14" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusGame.statusHuay14 ? "flex" : "none"}}>
                <img src={IMAGE_URL+'huay14.png'} onClick={()=>{playGames(5)}} alt="" className="img-fluid"  width="607" style={{height: '185px'}}/>
            </div> */}
            <div id="bet_filx" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusGame.statusBetflix ? "flex" : "none"}}>
                <img src={IMAGE_URL+'BETFILX.webp?v=0.1'} onClick={()=>{playGames(1,'')}} alt="" className="img-fluid"  width="607" style={{height: '185px'}}/>
            </div>
             <div id="sbo_bet"  className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusGame.statusSbobet ? "flex" : "none"}}>
                <img src={IMAGE_URL+'SBO.jpeg?v=0.1'} onClick={()=>{playGames(2,'')}} alt="" className="img-fluid" width="607" style={{height: '185px'}}/>
            </div>
            <div id="sa_game" className="game-card mb-2 text-center containgame" style={{cursor: 'pointer',display: statusGame.statusSagame ? "flex" : "none"}}>
                <img src={IMAGE_URL+'SAGaming.gif?v=0.1'} onClick={()=>{playGames(6,'')}} alt="" className="img-fluid" width="607" style={{height: '185px'}}/>
            </div>
            {/* <div className="game-card mb-2 text-center containgame" style={{cursor: 'pointer'}}>
                <img src={IMAGE_URL+'UFA.webp'} onClick={()=>{playGames(3)}} alt="" className="img-fluid" width="607" style={{height: '185px'}}/>
            </div> */}
        </div>
        </>
    );
}
export default PlayGame;